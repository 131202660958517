/** @format */

.pageHeader {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu {
  flex: 9;
  height: 50px;
  background-color: rgb(220, 218, 217);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.pageHeader a {
  text-decoration: none;
}

.pageHeader div {
  color: rgb(106, 162, 208);
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0px 5px;
}

.pageHeader div:hover {
  color: red;
}

@media print {
  .pageHeader {
    display: none;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
}

.actions > span {
  display: flex;
  background-color: rgb(220, 218, 217);
}

.actions > span > div {
  display: flex;
  align-items: center;
  background-color: white;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 13px;
  text-align: center;
  height: 37px;
  width: 46px;
}

.actions > span > div > div {
  text-align: center;
  background-color: rgb(21, 179, 26);
  border-radius: 18px;
}

.actions svg {
  width: 19px;
  margin: 3px;
  margin-left: 4px;
}

.actions::before {
  content: ' ';
  width: 20px;
  border-bottom-right-radius: 15px;
  background-color: rgb(220, 218, 217);
}

.actions::after {
  content: ' ';
  width: 48px;
  border-bottom-left-radius: 15px;
  background-color: rgb(220, 218, 217);
}
